.statisticsBox {
    padding: 32px;
    border-radius: 20px;
    background-color: #303030;
    position: relative;  

    @media screen and (max-width: 680px) {
        padding: 24px
    }

    p {
        margin-top: 16px
    }  
    
    &.fullDark {
        background-color: transparent;
    }
}