@import './reset';

//atoms
@import '../components/atoms/button/style';
@import '../components/atoms/container/style';
@import '../components/atoms/image/style';
@import '../components/atoms/text/style';

//molecules
@import '../components/molecules/accordion/style';
@import '../components/molecules/productInfo/style';
@import '../components/molecules/checkItem/style';
@import '../components/molecules/sliderDeviceModels/style';
@import '../components/molecules/statisticsBox/style';
@import '../components/molecules/textBox/style';
@import '../components/molecules/languagesDropdown/style';
@import '../components/molecules/sliderCategories/style';

//organisms
@import '../components/organisms/header/style';
@import '../components/organisms/footer/style';
@import '../components/organisms/menu/style';
@import '../components/organisms/modal/style';

//sections
@import '../components/sections/banner/style';
@import '../components/sections/caseStudyBanner/style';
@import '../components/sections/caseStudyBody/style';
@import '../components/sections/categoriesList/style';
@import '../components/sections/catalogueList/style';
@import '../components/sections/blocksLinkList/style';
@import '../components/sections/checklist/style';
@import '../components/sections/faqsList/style';
@import '../components/sections/introSectionProduct/style';
@import '../components/sections/introSectionImage/style';
@import '../components/sections/introSection/style';
@import '../components/sections/ecosystem/style';
@import '../components/sections/listTextImage/style';
@import '../components/sections/newsBody/style';
@import '../components/sections/products/style';
@import '../components/sections/projectDetail/style';
@import '../components/sections/legalDetail/style';
@import '../components/sections/section404/style';
@import '../components/sections/testimonials/style';
@import '../components/sections/textButtons/style';
@import '../components/sections/becomeAPartnerDetail/style';
@import '../components/sections/partnershipsDetail/style';
@import '../components/sections/devicesCompatibilityDetail/style';
@import '../components/sections/simpleTextBlock/style';
@import '../components/sections/trustedBy/style';
@import '../components/sections/statistics/style';





@font-face {
    font-family: 'Roboto-Light';
    font-style: 'light';
    font-weight: '200';
    src: url('../../public/fonts/Roboto/Roboto-Light.woff'), url('../../public/fonts/Roboto/Roboto-Light.woff2');
}
@font-face {
    font-family: 'Roboto-Light-Italic';
    font-style: 'light';
    font-weight: '200';
    src: url('../../public/fonts/Roboto/Roboto-LightItalic.woff'), url('../../public/fonts/Roboto/Roboto-LightItalic.woff2');
}
@font-face {
    font-family: 'Roboto-Medium';
    font-style: 'medium';
    font-weight: '400';
    src: url('../../public/fonts/Roboto/Roboto-Medium.woff'), url('../../public/fonts/Roboto/Roboto-Medium.woff2');
}
@font-face {
    font-family: 'Roboto-Medium-Italic';
    font-style: 'medium';
    font-weight: '400';
    src: url('../../public/fonts/Roboto/Roboto-MediumItalic.woff'), url('../../public/fonts/Roboto/Roboto-MediumItalic.woff2');
}
@font-face {
    font-family: 'Roboto-Bold';
    font-style: 'bold';
    font-weight: '600';
    src: url('../../public/fonts/Roboto/Roboto-Bold.woff'), url('../../public/fonts/Roboto/Roboto-Bold.woff2');
}
@font-face {
    font-family: 'Roboto-Bold-Italic';
    font-style: 'bold';
    font-weight: '600';
    src: url('../../public/fonts/Roboto/Roboto-BoldItalic.woff'), url('../../public/fonts/Roboto/Roboto-BoldItalic.woff2');
}


@font-face {
    font-family: 'Poppins-ExtraLight';
    font-style: 'extralight';
    font-weight: '100';
    src: url('../../public/fonts/Poppins/Poppins-ExtraLight.woff'), url('../../public/fonts/Poppins/Poppins-ExtraLight.woff2');
}
@font-face {
    font-family: 'Poppins-Light';
    font-style: 'light';
    font-weight: '200';
    src: url('../../public/fonts/Poppins/Poppins-Light.woff'), url('../../public/fonts/Poppins/Poppins-Light.woff2');
}
@font-face {
    font-family: 'Poppins-Regular';
    font-style: 'regular';
    font-weight: '300';
    src: url('../../public/fonts/Poppins/Poppins-Regular.woff'), url('../../public/fonts/Poppins/Poppins-Regular.woff2');
}
@font-face {
    font-family: 'Poppins-Medium';
    font-style: 'medium';
    font-weight: '400';
    src: url('../../public/fonts/Poppins/Poppins-Medium.woff'), url('../../public/fonts/Poppins/Poppins-Medium.woff2');
}
@font-face {
    font-family: 'Poppins-SemiBold';
    font-style: 'semi-bold';
    font-weight: '500';
    src: url('../../public/fonts/Poppins/Poppins-SemiBold.woff'), url('../../public/fonts/Poppins/Poppins-SemiBold.woff2');
}
@font-face {
    font-family: 'Poppins-Bold';
    font-style: 'bold';
    font-weight: '600';
    src: url('../../public/fonts/Poppins/Poppins-Bold.woff'), url('../../public/fonts/Poppins/Poppins-Bold.woff2');
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #1e1e1e;

    &.overflow-hidden {
        overflow: hidden;
    }

    /* width */
    &::-webkit-scrollbar {
        width: 5px;        
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #3a3a3a;
        border-radius: 4px;
    }
}
a {
    text-decoration: none;
}
section {
    &.bgWhite {
        background-color: #FFFFFF;
    }
    &.bgGrey {
        background-color: #E7E7E7;
    }
    &.bgDarkGrey {
        background-color: #1E1E1E;
    }
}
.margin-firstSectionTop {
    padding-top: 216px;

    @media screen and (max-height: 991px) {
        padding-top: 160px;
    }
}
.margin-sectionTop {
    padding-top: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));

    @media screen and (max-width: 680px) {
        padding-top: 60px;
    }
    @media screen and (max-height: 700px)  {
        padding-top: 60px;
    }
}
.margin-sectionBottom {
    padding-bottom: 120px;

    @media screen and (max-width: 720px) {
        padding-bottom: 80px;
    }
}
.margin-small-sectionTop {
    padding-top: 80px;
}
.margin-small-sectionBottom {
    padding-bottom: 80px;
}
.margin-sectionTitle {
    margin-bottom: calc(48px + (112 - 48) * ((100vw - 320px) / (1920 - 320)));
}

img {
    display: block;
}

.loadingPage{
    height: 100vh;
}

.showMore-button-container {
    width: 100%;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

h1, h2, h3, h4, h5, h6, p, span, li {
    white-space: pre-line;
}