.becomeAPartnerDetail {

    &.banner .container {
        position: relative; 
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: calc( 100vh - 216px);

        @media screen and (max-height: 991px) {
            min-height: calc(100vh - 160px);
        }

        h1 {
            margin-bottom: 30px;
            margin-top: -15vh;
        }
        
        h1, h2 {
            max-width: 1024px;
            text-align: center;
        }

        .mouse-icon-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;


            @media screen and (min-height: 700px) {
                position: absolute;
                bottom: 85px;
            }      

            img {
                height: 30px;
                animation: arrowAnim 2s ease-in-out 1s infinite;
                position: absolute;
                top:-25px;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
            @media screen and (min-width: 680px) and (max-height: 700px) {
                display: none;
            }
        } 
    }

    &.businessModel .container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p,span,ul,ol,li {
            max-width: 900px;
            text-align: center;
            color: #FFFFFF;
            font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
            line-height: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
        }

        h3 {
            margin-bottom: 40px;
            text-align: center;

            @media screen and (max-width: 680px) {
                margin-bottom: 20px;
            }
            @media screen and (max-height: 700px)  {
                margin-bottom: 20px;
            }
        }

        .description {
            margin-bottom: 80px;

            @media screen and (max-width: 680px) {
                margin-bottom: 60px;
            }
            @media screen and (max-height: 700px)  {
                margin-bottom: 60px;
            }
        }

        .buttons-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 60px 0;
        }

    }

    .background-image {
        height: 120vh;
        position: absolute;
        z-index: -1;
        top: 20vh;
    }

}

.becomeAPartner-area {
    overflow-x: hidden;
}