.banner {

    .info {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        @media screen and (max-width: 1023px) {
            display: none;
        }
    }

    .swiper {
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: grab;
        padding-bottom: 24px;

        .swiper-slide {
            min-height: 100vh;
            height: max-content;

            @media screen and (min-height: 761px) {
                display: flex;
                align-items: center;
            }

            .backgroundImage {
                width: 100%;
                min-height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
            }

            .text-container {
                max-width: 720px;
                display: flex;
                flex-direction: column;
                //justify-content: space-between;
                max-height: 630px;

                @media screen and (max-width: 1200px) {
                    max-width: 600px;
                }
                @media screen and (max-width: 720px) {
                    h1, p {
                        max-width: 100%;
                    }
                }
                @media screen and (max-height: 991px) and (min-width: 700px) {
                    h1 {
                        font-size: 50px;
                        line-height: 60px;
                    }
                    p {
                        font-size: 20px;
                        line-height: 24px
                    }
                }
                @media screen and (min-height: 650px) {
                    height: calc(100vh - 350px);
                }
                
                .buttons-container {
                    display: flex;
                    align-items: center;
                    margin-top: 46px;
                }

                p {
                    margin-top: 100px;

                    @media screen and (max-width: 1024px) {
                        margin-top: 40px;
                    }
                }
            }
        }

        .swiper-buttons-container {
            position: relative;
            width: 1332px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-end;
            position: relative;
            margin: 32px 0;
            left:0;
            right: 0;
            margin: auto;

            @media screen and (min-height: 700px) {
                position: absolute;
                bottom: 60px;
                left:0;
                right: 0;
                margin: auto;
            }  

            @media screen and (max-width: 800px) {
                position: absolute;
                bottom: 60px;
                left:0;
                right: 0;
                margin: auto;
            }
    
            @media screen and (max-width: 1500px) {
                width: calc(100% - 180px);
                margin-left: 90px;
                margin-right: 90px;            
            }
            @media screen and (max-width: 768px) {
                width: calc(100% - 120px);
                margin-left: 60px;
                margin-right: 60px;            
            }
            @media screen and (max-width: 800px) and (max-height: 650px) {
                display: none;
            }
            @media screen and (min-width: 801px) and (max-height: 700px) {
                display: none;
            }
            @media screen and (max-width: 576px) {
                width: calc(100% - 40px);
                margin-left: 20px;
                margin-right: 20px;            
            }

            .swiper-button-prev, .swiper-button-next {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                border: 1px solid #987700;
                z-index: 10000;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;


                svg {
                    width: 16px;
                    height: 16px;
                    transition: all 0.3s ease-in-out;

                    .st1 {
                        fill: none;
                    }
                }

                &.swiper-button-disabled {
                    opacity: 0.5;
                    pointer-events: none;
                    cursor: default;
                }

                &:hover {
                    background-color: #987700;

                    svg .st1 {
                        stroke: #FFFFFF;
                    }
                }
            }

            .swiper-button-prev svg {
                transform: rotate(180deg);
                margin-right: 2px;
                margin-top: 2px;
            }

            .swiper-button-next { 
                margin-left: 6px;

                svg {
                    margin-left: 3px;
                    margin-top: 2px;
                }
            }
        }

        .swiper-pagination {
            position: relative;
            width: 1332px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            align-items: center;
            flex-direction: row;
            min-height: 20px;
            position: absolute;
            bottom: 55px;
            left:0;
            right: 0;
            margin: auto;
            margin-bottom: 17px;

            @media screen and (max-width: 800px) and (min-height: 700px) {
                position: absolute;
                bottom: 70px;
                left:0;
                right: 0;
                margin: auto;
            }
    
            @media screen and (max-width: 1500px) {
                width: calc(100% - 180px);
                margin-left: 90px;
                margin-right: 90px;           
            }
            @media screen and (max-width: 768px) {
                width: calc(100% - 120px);
                margin-left: 60px;
                margin-right: 60px;            
            }
            @media screen and (max-width: 800px) and (max-height: 650px) {
                display: none;
            }
            @media screen and (min-width: 801px) and (max-height: 700px) {
                display: none;
            }
            @media screen and (max-width: 576px) {
                width: calc(100% - 40px);
                margin-left: 20px;
                margin-right: 20px;            
            }


            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                background-color:#414040;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.swiper-pagination-bullet-active {
                    border: 1px solid #987700;
                    height: 18px;
                    width: 18px;
                    background-color: transparent;
                }
            }
        }

        .mouse-icon-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            @media screen and (min-height: 300px) {
                position: absolute;
                bottom: 85px;
            }      

            img {
                height: 30px;
                animation: arrowAnim 2s ease-in-out 1s infinite;
                position: absolute;
                top:-25px;
            }

            @media screen and (max-width: 768px) {
                display: none;
            }
            @media screen and (max-width: 800px) and (max-height: 650px) {
                display: none;
            }
            @media screen and (min-width: 801px) and (max-height: 700px) {
                display: none;
            }
        }        
    } 
    
    .descriptionButtons-container {
        display: flex;
        flex-direction: column;

        .description {
            max-width: 550px;
        }

        @media screen and (max-width: 1024px) {
            flex-direction: column-reverse;
        }
    }
}


@keyframes arrowAnim {
    0% {
        top: -5px;
    }
    
    50% {
        top: 0px;
    }

    100% {
        top: -5px;
    }
  }