.catalogueList {
    position: relative;

    &.bg-dark {
        background-color: #1E1E1E;
    }

    &.bg-white  {
        background-color: #FFFFFF;
    }

    .catalogueList-content {
        position: relative;
        border-bottom: 2px solid #e7e7e7;
        margin-bottom: 80px;
        

        .category-box {
            position: relative;
            padding: 24px 0;
            border-top: 2px solid #e7e7e7;
            cursor: pointer;

            > .files-container {
                transition: max-height 1s ease-in-out;
                max-height: 10000px;
                overflow: hidden;
            }

            .title-container {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h2 {
                    width: 100%;
                    max-width: calc(100% - 50px);
                    word-wrap: break-word;
                }

                h3, span {
                    transition:  all 0.3s ease-in-out;
                }

                &:hover h2, &.open h2 {
                    color: #000000;
                }

                .button {
                    display: block;
                    position: relative;
                    /*Change to resize the plusminus box*/
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid #b1b1b1;
                  }
                  
                  .button span:nth-child(1) {
                    display: block;
                    position: absolute;
                    margin: 0 20%;
                    width: 60%;
                    height: 2px;
                    border-radius: 3px;
                    background: #b1b1b1;
                    /*- half the height*/
                    top: 9px;
                    -moz-transition: all .25s ease;
                    -webkit-transition: all .25s ease;
                    -o-transition: all .25s ease;
                    transition: all .25s ease;
                  }
                  
                  .button span:nth-child(2) {
                    display: block;
                    position: absolute;
                    margin: 20% 0;
                    width: 2px;
                    height: 60%;
                    border-radius: 3px;
                    background: #b1b1b1;
                    /*- half the width*/
                    left: 9px;
                    -moz-transition: all .25s ease;
                    -webkit-transition: all .25s ease;
                    -o-transition: all .25s ease;
                    transition: all .25s ease;
                  }
                                
                  

                &.closed {
                    ~ .files-container {
                        overflow: hidden;
                        max-height: 0;
                        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
                    }
                }

                &.open {
                    .button span:nth-child(2) {
                        -moz-transform: rotate(-90deg);
                        -webkit-transform: rotate(-90deg);
                        -o-transform: rotate(-90deg);
                        transform: rotate(-90deg);
                    }
                }
            }

            
        }

        .fileContent {
            position: relative;
            width: 100%;

            &:first-child {
                margin-top: 24px;
            }


            .fileName {
                padding: 12px 0;
                width: 100%;
                cursor: pointer;



                &.closed {
                    ~ .fileVersions {
                        overflow: hidden;
                        max-height: 0;
                        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
                    }
                }

                &:hover h3, &.open h3 {
                    color: #000000;
                }
            }

            .fileVersions {
                transition: max-height 1s ease-in-out;
                max-height: 10000px;
                overflow: hidden;

                .langFiles-container:last-child {
                    margin-bottom: 24px;
                }
                
                .fileLinks {
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    width: 100%;

                    span:first-child {
                        margin-right: 24px;
                        margin-top: -1px;
                    }
                    span:nth-child(2) {
                        margin-right: 6px;
                    }    
        
                    &:hover {
                        span:first-child {
                            color: #000000;
                        }
                    }
                }
            }

        }

    }  
}