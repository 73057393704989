.productInfo {
    padding: 36px;
    border-radius: 20px;
    background-color: #303030;
    position: relative;  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 500px) {
        padding: 40px
    }

    .title-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 16px;

        @media screen and (min-width: 768px) {
            min-height: calc((32px + (46 - 32) * ((100vw - 320px) / (1920 - 320))) * 2);
        }
        

        h2 {
            max-width: calc(100% - 80px);
            text-wrap: wrap;

            @media screen and (max-width: 990px) { 
                max-width: 100%;
            }
        }
    }

    p {
        margin-bottom: 24px;
    }


    .button-container {
        margin-top: 16px;
        width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
        position: absolute;
        bottom: 36px;
        right: 36px;
        display: none;
    }

    > img {
        @media screen and (max-width: 990px) {
            margin-bottom: 16px;
        }
        @media screen and (max-width: 768px) {
            max-width: 80%;
        }
    }

    
    @media screen and (max-width: 990px) {
        padding-bottom: 76px;

        .title-container button {
            display: none;
        }

        .button-container {
            display: flex;
        }
    }

   


    @media screen and (max-width: 680px) {
        padding-left: 24px;
        padding-top: 24px;
        padding-right: 24px;

        .button-container {
            bottom: 24px;
            right: 24px;
        }
    }
    
}