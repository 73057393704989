.testimonials {
    
    .swiper {
        background-color: #8E7619;
        padding: 90px 0 100px;
        cursor: grab;

        @media screen and (max-width: 911px) {
            padding: 60px 0 80px;     
        }

        .swiper-slide {

            .container {
                display: flex;
                flex-direction: row;

                @media screen and (max-width: 911px) {
                   flex-direction: column;
                }

                > div {
                    width: 50%;

                    @media screen and (max-width: 911px) {
                        width: 100%;
                    }

                    &:first-child {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-end;
                        width: calc((100% / 2 ) - 120px );
                        padding-right: 120px;

                        @media screen and (max-width: 911px) {
                            padding-right: 0;
                            width: 100%;
                            justify-content: flex-start;
                            margin-bottom: 40px;
                        }
                        
                        img {
                            width: 200px;
                            object-fit: contain;
                        }
                    }

                    &:nth-child(2) {
                        max-width: 600px;
                    }

                    span {
                        display: block;
                    }

                    .author {
                        margin-top: 24px;
                    }
                }
            }
        }

        .swiper-pagination {
            position: absolute;
            bottom: 50px;
            margin-left: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            @media screen and (max-width: 911px) {
                bottom: 30px;    
            }
            
            @media screen and (max-width: 911px) {
                margin-left: 0;
                left: 0;
                width: calc(100% - 180px);
                margin-left: 90px; 
                margin-right: 90px;        
            }
            @media screen and (max-width: 768px) {
                width: calc(100% - 120px);
                margin-left: 60px;  
                margin-right: 60px;       
            }
            @media screen and (max-width: 576px) {
                width: calc(100% - 40px);
                margin-left: 20px;  
                margin-right: 20px;       
            }

            .swiper-pagination-bullet {
                background-color: #1E1E1E;
                opacity: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;

                &.swiper-pagination-bullet-active {
                    width: 17px;
                    height: 17px;
                    background-color: transparent;
                    border: 1px solid #1E1E1E;
                }
            }

        }
    }
}