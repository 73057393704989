.checkitem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    img {
        width: 27px;
        margin-right: 12px;
        margin-top: 4px
    }

    h1, h2, h3, p, span, li {
        color:#818181 !important;
    }
}