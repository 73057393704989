.introSectionProduct {
    
    &.bg-light  {
        background-color: #E7E7E7;
        padding-bottom: 0!important;

        .text-container {
            h1 {
                color: #000000;
            }
        }

        .description-container {
            background-color: #DDDDDD;
            padding: calc(30px + (80 - 30) * ((100vw - 320px) / (1920 - 320))) 0;

            .description { 
                color: #000000;
            }
        }
    }

    .title-container.bgImage {
        position: relative;
        width: 100%;

        img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-position: center;
            object-fit: cover;
            height: 50vh;
        }
    }

    &.bg-light .title-container.bgImage {
        @media screen and (max-width:1365px) {
            padding-bottom: 60px;
        }
    }

    .text-container {

        h3 {
            padding-bottom: calc(16px + (48 - 16) * ((100vw - 320px) / (1920 - 320)));;
            max-width: 700px;
        }

        h1 {
            max-width: 750px;
            margin-bottom: 32px;

            &.mb {
                padding-bottom: calc(30px + (140 - 30) * ((100vw - 320px) / (1920 - 320)));
            }   
        }

        p {
            margin-bottom: 42px;
        }
    } 

    .image-container {
        width: 100%;
        margin-top: calc(80px + (120 - 80) * ((100vw - 320px) / (1920 - 320)));;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width:100%
        }

    }

    .infoNumbers-container {
        margin-top: calc(80px + (240 - 80) * ((100vw - 320px) / (1920 - 320)));
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 20px;
        
        @media screen and (max-width: 1320px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
    
}