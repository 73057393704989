.textBox-content {
    .paragraph {
        margin-bottom: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
    .paragraph + .list {
        margin-top: calc(-8px + (-24 + 16) * ((100vw - 320px) / (1920 - 320)));
    }

    .heading {
        margin-bottom: 8px;
    }

    ul, ol {
        margin-left: 32px;
    }

    ul, ul li {
        list-style: disc;
        list-style-position: inside;
        margin-bottom: 10px;
    }

    ol li {
        list-style-position: inside;
        margin-bottom: 10px;
        font-family: 'roboto-light', sans-serif;
    } 

    p {
        min-height: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    }

    &.projects {
        .paragraph, .list {
            margin-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
        }

        .paragraph + .list {
            margin-top: 0;
        }
    }

    &.news-body {
        h1, h2, h3, h4, p, span, ol, li, ul {
            color: #b1b1b1;
        }
    }

}