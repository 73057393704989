.header {
    background-color: transparent;
    height: 120px;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    transition: all 0.5s ease-in-out;


    .header-logo {
        z-index: 10000;
        position: relative;
        transition: all 0.3s ease-in-out;
        object-fit: contain;
        height: 40px;
    }

    &.bg-white, &.darkMode {

        .header-logo {
            filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(158deg)
            brightness(108%) contrast(109%);
        }

        .text span {
            color: #000000
        }
        .header-dropdown .text .arrow {
            filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(158deg)
            brightness(108%) contrast(109%);
        }

        button.lineColored {
            color: #000000!important;

            &:hover {
                color:#FFFFFF!important
            }
        }

        button.fullColored:hover {
            background-color: #000000!important;
            color: #FFFFFF!important
        }

        .hamburger span{
            background-color: #000000;
        }
    }

    &.bg-white {
        background-color: #FFFFFF;
    }
    

    .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dropdownLinks {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-right: 60px;
        width: 100%;
        justify-content: center;

        @media screen and (max-width: 1700px) {
            margin: 0 40px;
        }

        @media screen and (max-width: 1365px) {
            display: none;
        }

    }

    .rightSide {
        display: flex;
        align-items: center;
        flex-direction: row;

        > div.buttons-container {
           display: flex;
           align-items: center;
           min-width: 150px;
           justify-content: flex-end;
           width:-webkit-fill-available;

            @media screen and (max-width: 1365px) {
                display: none;
            }

        }

        > div.buttons-container button, > div.buttons-container a button {
            min-width: fit-content;
        }
    }

    .header-dropdown {
        margin: 0 16px;
        min-width: fit-content;

        &.position-relative {
            position: relative;
        }

        .text {
            display: flex;
            align-items: center;
            flex-direction: row;
            position: relative;
    
            &:hover span {
                color: #987700;
            }
    
            span {
                text-align: center;
                cursor: pointer;
            }
    
            .arrow {
                width: 8px;
                margin-left: 8px;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
            }

            .arrow-up {
                position: absolute;
                bottom: -28px;
                left: 35%;    
                width: 0; 
                height: 0; 
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;        
                border-bottom: 10px solid #DBDBDB;
                border-radius: 2px;
                display: none;

                &.visible {
                    display: block;
                }
            }  
        
        }
    
        .items {
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: 50px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
            a:hover span {
                color:#987700
            }
        }
    
        .items.simple {        
            background-color: #DBDBDB;
            border-radius: 16px;
            display: flex;
            flex-direction: column;
            width: 300px;
            left: -60px;

            a {
                transition: all 0.3s ease-in-out;
                border-radius: 8px;
                padding: 8px 6px;

                &:hover {
                    background-color: #d1d1d1;
                }
            }

            > div {
                padding: 20px;
                display: flex;
                flex-direction: column;
            }

            /* width */
            &::-webkit-scrollbar {
                height: 0;
            }  
        }

        .items:not(.simple) {
            background-color: #DBDBDB;
            border-radius: 16px; 
            padding: 16px 0; 
            margin: 0 auto;
            left: -100px;
            
            .arrow-up {
                left: 340px;
                width: 0px;

                @media screen and (max-width: 1500px) {
                    left: 330px
                }
            }

            > div {
                display: grid;
                width: 100%;
                min-width: max-content;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                
                a {
                    width: 300px;
                    height: 100px;
                    display: flex;
                    flex-direction: column;
                    padding: 16px;
                    background-color: transparent;
                    transition: all 0.3s ease-in-out;
                    border-radius: 8px;
                    margin: 8px 16px 0px 16px;
                    position: relative;
                    padding-bottom: 50px;

                    p {
                        margin-top: 12px;
                    }

                    &:hover {
                        background-color: #d1d1d1;
                    }

                    .image-container {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        position: absolute;
                        bottom: 16px;
                        right: 20px;

                        img {
                            max-width: 30px;
                            max-height: 30px;
                            object-fit: contain;
                        }
                    }

                    &:not(:nth-child(3n)):after {
                        content: '';
                        height: 80%;
                        width: 1px;
                        background-color: #C1C1C1;
                        position: absolute;
                        right: -16px;
                        top: 16px;
                    }
                }
            }
        }

    
        &.open {
            .items {
                opacity: 1;
                pointer-events: all;
            }
            .text .arrow {
                transform: rotate(180deg);
            }
            .text span {
                color:#987700
            }
        }
    
    
    }

    .hamburger {
        display: none;
        cursor: pointer;
        margin-left: 24px;
        width: 30px;
        height: 30px;
        margin-top: 2px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 10000;
        position: relative;

        @media screen and (max-width: 1365px) {
            display: flex;
        }

        span {
            display: block;
            width: 28px;
            border-radius: 4px;
            height: 2px;
            background-color: #FFFFFF;
            transition: all 0.3s ease;
            position: relative;
            margin-top: -3px;

            + span {
                margin-top: 6px
            }

            &:first-child {
                animation: ease 0.7s top2 forwards;
            }
            &:nth-child(2) {
                animation: ease 0.7s bottom2 forwards;
            }
        }

        &:hover span, &.open span {
            background-color: #987700;
        }

        &.open {
            span:first-child {
                animation: ease 0.7s top forwards;
            }
            span:nth-child(2) {
                animation: ease 0.7s bottom forwards;
            }
        }
    }

    @media screen and (max-width: 680px) {
        .rightSide button {
            display: none;
        }
    }
}

@keyframes top {
    0% {
        top: 0;
        transform: rotate(0);
    }
    50% {
        top: 4px;
        transform: rotate(0);
    }
    100% {
        top: 4px;
        transform: rotate(45deg);
    }
}

@keyframes top2 {
    0% {
        top: 4px;
        transform: rotate(45deg);
    }
    50% {
        top: 4px;
        transform: rotate(0deg);
    }
    100% {
        top: 0;
        transform: rotate(0deg);
    }
}

@keyframes bottom {
    0% {
        bottom: 0;
        transform: rotate(0);
    }
    50% {
        bottom: 4px;
        transform: rotate(0);
    }
    100% {
        bottom: 4px;
        transform: rotate(135deg);
    }
}

@keyframes bottom2 {
    0% {
        bottom: 4px;
        transform: rotate(135deg);
    }
    50% {
        bottom: 4px;
        transform: rotate(0);
    }
    100% {
        bottom: 0;
        transform: rotate(0);
    }
}
