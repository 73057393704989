// container sizes
$lg: 1650px;
$md: 1332px;
$sm: 1024px;

.container {
    position: relative;
    
    &.full {
        width: 100%;
    }

    &.lg {
        width: $lg;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 1800px) {
            width: calc(100% - 180px);
            margin-left: 90px;
            margin-right: 90px;          
        }
        @media screen and (max-width: 768px) {
            width: calc(100% - 120px);
            margin-left: 60px;
            margin-right: 60px;            
        }
        @media screen and (max-width: 576px) {
            width: calc(100% - 40px);
            margin-left: 20px;
            margin-right: 20px;            
        }
    }

    
    &.md {
        width: $md;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 1500px) {
            width: calc(100% - 180px);
            margin-left: 90px;
            margin-right: 90px;            
        }
        @media screen and (max-width: 768px) {
            width: calc(100% - 120px);
            margin-left: 60px;
            margin-right: 60px;            
        }
        @media screen and (max-width: 576px) {
            width: calc(100% - 40px);
            margin-left: 20px;
            margin-right: 20px;            
        }
    }

    &.md-right {
        width: calc($md + ((100% - $md) / 2));
        margin-left: calc((100% - $md) / 2);
        margin-right: 0;

        @media screen and (max-width: 1500px) {
            width: calc(100% - 90px);
            margin-left: 90px;         
        }
        @media screen and (max-width: 768px) {
            width: calc(100% - 60px);
            margin-left: 60px;         
        }
        @media screen and (max-width: 576px) {
            width: calc(100% - 20px);
            margin-left: 20px;         
        }
    }
    
    &.sm {
        width: $sm;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: 1300px) {
            width: calc(100% - 180px);
            margin-left: 90px;
            margin-right: 90px;            
        }

        @media screen and (max-width: 768px) {
            width: calc(100% - 120px);
            margin-left: 60px;
            margin-right: 60px;            
        }
        @media screen and (max-width: 576px) {
            width: calc(100% - 40px);
            margin-left: 20px;
            margin-right: 20px;            
        }

    }
}