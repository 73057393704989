.introSection {
    &.bg-dark {
        background-color: #1E1E1E;
    }

    &.bg-light, &.bg-white  {
        background-color: #E7E7E7;

        .text-container {
            h1 {
                color: #000000;
            }
        }

        .description-container {
            background-color: #DDDDDD;
            padding: calc(30px + (80 - 30) * ((100vw - 320px) / (1920 - 320))) 0;

            .description { 
                color: #000000;
            }
        }
    }

    &.bg-white {
        background-color: #FFFFFF;
    }

    .text-container {

        h3 {
            padding-bottom: calc(16px + (48 - 16) * ((100vw - 320px) / (1920 - 320)));;
            max-width: 700px;
        }

        h1 {
            max-width: 750px;
            overflow: visible;

            &.mb {
                padding-bottom: calc(30px + (140 - 30) * ((100vw - 320px) / (1920 - 320)));
            }           

            &.mb24 {
                margin-bottom: 24px;
            }
        }
    }

    .description-container p {
        margin-bottom: 42px;
    }
    
}