.legalDetail {
    background-color: #FFFFFF;

    .container > h3 {
        padding-bottom: calc(40px + (62 - 40) * ((100vw - 320px) / (1920 - 320)));
        text-align: left;
    }  

    .pages-container {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .frontPage {
        padding-bottom: 24px;
        margin-bottom: 40px;
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease-in-out;

        &.tabs {
            border-bottom: 2px solid #987700;
        }
    }

    .frontPage > button {
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;
        margin-bottom: calc(16px + (32 - 16) * ((100vw - 320px) / (1920 - 320)));
        text-align: left;

        @media screen and (max-width: 620px) {
            h2 {font-size: 26px!important;}
        }
       
        &:hover, &.open {
            h2 {
                color: #987700;
            }
        }  
    }

    .infoPage {
        background-color: #FFFFFF;
        position: relative;
        width: 100%;
        top: 100vh;
        transition: top 0.3s ease-in-out;
        height: fit-content;
        max-height: 0;
        overflow: hidden;

        &.open {
            top: 0;
            max-height: 100%;
        }

        h1 {
            margin-bottom: calc(16px + (62 - 16) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}