.modal {
    background-color: rgba(26, 26, 26, 0.9);
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000000;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-height: 0;
    overflow-y: auto;
    transition: all 0.5s ease-in-out;

    /* width */
    &::-webkit-scrollbar {
        width: 0;        
    }

    &.open {
       max-height: 100vh;
       height: 100vh;
    }

    > div {
        background-color: #232323;
        padding: 60px;
        width: 100%;
        max-width: 900px;
        min-width: 50%;
        border-radius: 12px;
        position: relative;
        overflow-y: auto;
        margin: 10vh;

        @media screen and (max-width: 1024px) {
            max-width: 80%;
            margin: 5vh;
        }

        @media screen and (max-width: 768px) {
            padding: 80px 20px 60px;
            margin: 2vh;
        }

        .closeButton {
            position: absolute;
            right: 30px;
            top: 30px;
            cursor: pointer;
            width: 50px;
            height: 51px;

            svg .cls-1  {
                transition: 0.3s ease-in-out;
            }

            svg:hover .cls-1  {
                fill: #4D4D4D;
            }

            @media screen and (max-width: 768px) {
                width: 40px;
                height: 41px;
            }
            
        }

        .title-container {
            width: 100%;
            text-align: center;
            margin-bottom: 60px;
        }

        form {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            width: 100%;


            > div  {
                position: relative;
                width: 100%;
                margin-bottom: 12px;
                display: flex;
                flex-direction: column;

                &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    width: calc(50% - 4px);
                }

                &:nth-child(2), &:nth-child(4) {
                    margin-right: 8px;
                }

                > span {
                    color:#8F7705!important;
                    font-family: "Poppins-SemiBold", sans-serif;
                    font-size: 12px;
                    margin-top: 8px;
                    margin-left: 8px;
                }
            }

            input, textarea {
                background-color: transparent;
                border-radius: 40px;
                border: 1px solid #4D4D4D;
                height: 32px;
                padding: 8px 16px;
                color: #8E8E8E;
                font-family: 'Poppins-Regular', sans-serif;
                font-size: 14px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    width: 100%;
                }

                &::placeholder {
                    font-family: 'Poppins-Regular', sans-serif;
                    font-size: 14px;
                    color: #585858;
                }

                
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                &[type=number] {
                    -moz-appearance: textfield;
                }
            }

            textarea {
                height: 100px;
                border-radius: 20px;
                resize: none;
            }

            .bottom-div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;

                /* Customize the label (the container) */
                .container {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    margin-bottom: 25px;
                    cursor: pointer;
                    font-size: 22px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }
                
                /* Hide the browser's default radio button */
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                
                /* Create a custom radio button */
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: #1A1A1A;
                    border-radius: 50%;
                }
                
                /* On mouse-over, add a grey background color */
                .container:hover input ~ .checkmark {
                    background-color: #8F7705;
                }
                
                /* When the radio button is checked, add a blue background */
                .container input:checked ~ .checkmark {
                    background-color: #1A1A1A;
                }
                
                /* Create the indicator (the dot/circle - hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                
                /* Show the indicator (dot/circle) when checked */
                .container input:checked ~ .checkmark:after {
                    display: block;
                }
                
                /* Style the indicator (dot/circle) */
                .container .checkmark:after {
                    top: 9px;
                    left: 9px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #8F7705;
                }

                .termsofservice {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                    justify-content: center;
                    margin: 16px 0;

                    input {
                        background-color: #1A1A1A;
                        border-radius: 50%;
                        width: 10px;
                        height: 10px;
                        cursor: pointer;
                    }

                    .paragraph {
                        margin-bottom: 0;
                        margin-top: 5px;
                    }
                    span {
                        font-family: 'poppins-regular'!important;
                        font-size: 12px!important;
                        line-height: 16px!important;
                        color: #b1b1b1!important;
                    }

                    a span {
                        color: #FFFFFF!important;
                        transition: all 0.3s ease-in-out;

                        &:hover{
                            color:#8F7705 !important;
                        }
                    }
                }
            }

            input[type=submit] {
                height: 52px;
                background-color: #8F7705;
                width: fit-content;
                color: #000000;
                font-family: "Poppins-SemiBold", sans-serif;
                border: none;
                min-width: 160px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background-color: #FFFFFF;
                }
            }


            @media screen and (max-width: 768px) {
                flex-direction: column;

                > div {
                    width: 100%!important;
                }
 
                input:not([type=submit]), textarea {
                    width: auto !important;
                    margin-right: 0;
                }
             }
        }
    }

}