.simpleTextBlock {
    background-color: #DDDDDD;
    padding-bottom: calc(44px + (84 - 44) * ((100vw - 320px) / (1920 - 320)));

    @media screen and (max-width: 680px) {
        padding-bottom: 44px;
    }

    .text-container {

        h3 {
            padding-bottom: calc(16px + (48 - 16) * ((100vw - 320px) / (1920 - 320)));;
            max-width: 700px;
        }

        h1 {
            max-width: 750px;
            overflow: visible;

            &.mb {
                padding-bottom: calc(30px + (140 - 30) * ((100vw - 320px) / (1920 - 320)));
            }           

            &.mb24 {
                margin-bottom: 24px;
            }
        }
    }
    
}