.newsDetail {
    position: relative;

    .text-container {
        h3 {
            margin-bottom: calc(16px + (48 - 16) * ((100vw - 320px) / (1920 - 320)));;
            max-width: 800px;
        }

        h1 {
            margin-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
            max-width: 800px;

            &.mb24 {
                margin-bottom: 24px;
            }
        }
    }

    .title-container {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap-reverse;

        .date {
            display: block;
            padding: 8px 24px;
            border-radius: 40px;
            border: 1px solid #4D4D4D;
            margin-bottom: 16px;
            margin-top: 8px;
        }
    }

   .newContent-container {
        img {
            position: relative;
            width: 100%;
            max-height: 627px;
            border-radius: 16px;
            margin-top: calc(30px + (90 - 30) * ((100vw - 320px) / (1920 - 320)));
        }
        
        .newContent-text {
            margin-top: calc(30px + (90 - 30) * ((100vw - 320px) / (1920 - 320)));

            @media screen and (min-width: 991px) {
                column-count: 2;
                column-gap: 40px;
            }        

            p {
                margin-bottom: 30px;
            }
        }
   }

    .button-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: calc(60px + (90 - 60) * ((100vw - 320px) / (1920 - 320)));
        margin-bottom: 60px;
    }
}