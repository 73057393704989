.languages-dropdown {
    position: relative;
    width: 200px;

    .footer-language-dropdown {
        padding: 16px 32px;
        border-radius: 30px;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        font-family: 'Poppins-Light', sans-serif;
        font-weight: 400;
        font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
        line-height: 20px;
        letter-spacing: 1px;
        color: #987700;
        border: 2px solid #987700;
        background-color: transparent;
        max-width: 200px;

        &:hover, &.open {
            background-color: #987700;
            color: #FFFFFF;
            border: 2px solid #987700;
        }

        span {
            height: 20px;
            overflow: hidden;
            text-align: center;
            transition: none;
            display: block;
        }
    }

    .footer-language-options {
        background-color: #FFFFFF;
        color: #987700;
        border: none;
        border-radius: 10px;
        margin: 5px 5%;
        width: 90%;
        overflow-y: auto;
        position: absolute;
        z-index: 10000;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

        &.open {
            max-height: 150px;
            transition: max-height 0.3s ease-in-out;

            > div {
                max-height: 150px;
                overflow-y: auto;
            }
        }

        > div {
            overflow: hidden;
            max-height: 0;

            /* width */
            &::-webkit-scrollbar {
                width: 2px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: #987700;
            }            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #FFFFFF;
            }

            .option {
                border: none;
                font-family: 'Poppins-Light', sans-serif;
                font-weight: 400;
                font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
                line-height: 16px;
                letter-spacing: 1px;
                padding: 12px;
                transition: all 0.3s ease-in-out;
                color: #987700;
                cursor: pointer;

                span {
                    height: 18px;
                    overflow: hidden;
                    text-align: center;
                    transition: none;
                    display: block;
                    line-height: 18px;
                }
                

                &:hover {
                    background-color: #987700;
                    color: #FFFFFF;
                }

                &.is-selected {
                    background-color: #987700;
                    color: #FFFFFF;
                    
                }
            }
        }
            
    }
}