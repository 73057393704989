.devicesCompatibilityDetail {

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            max-width: 1024px;
            text-align: center;
            margin: 60px 0;
        }

    }
}