.introducing {

    .sectionTitle-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image-container {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: center;
        }

        .image-title {
            position: absolute;
            top: 180px;
            left: 230px;
            max-width: 300px;

            @media screen and (max-width: 1366px) {
                left: 200px;
            }

            @media screen and (max-width: 1200px) {
                position: relative!important;
                max-width: 100%!important;
                top: 0!important;
                text-align: center;
                left: 0;
            }

        }

        img {            
            width: 100%;
        }

        .image {
            position: relative;
        }

        .image-interactive-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .linkTop, .linkLeft, .linkRight {
                width: 100px;
                height: 100px;
                position: absolute;
                cursor: pointer;

                @media screen and (max-width: 991px) {
                    display: none;
                }
            }

            .linkTop {
                top:17%;
                left: 55%;         
            }

            .linkLeft {
                bottom:19%;
                left: 7%; 
            }

            .linkRight {
                bottom:19%;
                right: 7%;  
            }
        }
    }
    .description {
        margin-top: 32px;
    }

    .list-container {
        margin-top: 64px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        width: 100%;
        gap: 16px;

        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        > div {
            position: relative;
            padding: 24px 32px;
            background-color: #303030;
            border-radius: 12px;
        }
    }

    .button-container {
        margin-top: 64px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}