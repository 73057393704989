.statistics {
    
    .image-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;

        img {
            width: 100%;
            max-height: 700px;
        }
    }


    .info-container > div {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 20px;
        margin: 80px 0;

        @media screen and (max-width: 1365px)  {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media screen and (max-width: 725px)  {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    &.fullDark {
        background-color: #1E1E1E;
    }
}