.sliderCategories {

    .container {
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        align-items: center;

        /* width */
        &::-webkit-scrollbar {
            height: 0;
        }        

        .item {
            padding: 12px 24px;
            border-radius: 36px;
            border: 2px solid #4D4D4D;
            color: #4D4D4D;
            font-family: "Poppins-regular", sans-serif;
            font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
            line-height: calc(24px + (40 - 24) * ((100vw - 320px) / (1920 - 320)));
            cursor: pointer;
            margin-right: 12px;
            transition: all 0.3s ease-in-out;

            &:last-child {
                margin-right: 40px;
            }


            &:hover {
                color: #FFFFFF;
            }

            &.active {
                color: #FFFFFF;
                border: 2px solid #FFFFFF;
            }
        }
    }
}