.sliderDeviceModels {

    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .sliderOptions {
        display: flex;
        align-items: center;
        justify-content: center;
        

        > div {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 1024px;
            width: 100%;
        }

        .item {
            padding: 12px 24px;
            border-radius: 40px;
            display: flex;
            justify-content: center;
            border: 2px solid #4D4D4D;
            color: #4D4D4D;
            font-family: "Poppins-regular", sans-serif;
            font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
            line-height: calc(24px + (32 - 24) * ((100vw - 320px) / (1920 - 320)));
            cursor: pointer;
            margin: 0 6px 12px;
            transition: background-color 0.5s ease-in-out;


            width: calc(100% / 6 - 12px);
            min-width: fit-content;

            @media screen and (max-width: 1360px) {
                width: calc(100% / 5 - 12px);
            }
            @media screen and (max-width: 1024px) {
                width: calc(100% / 4 - 12px);
            }
            @media screen and (max-width: 991px) {
                width: calc(100% / 3 - 12px);
            }

            &:hover {
                color: #FFFFFF;
            }

            &.active {
                background-color: #FFFFFF;
                border: 2px solid #FFFFFF;
                color: #1E1E1E;
            }
        }
    }

    .sliderResults {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        > div {
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            max-width: 1024px;
            width: 100%;
        }


        .item {
            margin: 8px 6px;
            text-align: center;

            width: calc(100% / 5 - 12px);
            min-width: fit-content;

            @media screen and (max-width: 1024px) {
                width: calc(100% / 4 - 12px);
            }
            @media screen and (max-width: 991px) {
                width: calc(100% / 3 - 12px);
            }
            @media screen and (max-width: 600px) {
                width: calc(100% / 2 - 12px);
            }

        }
    }
}