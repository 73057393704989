.projectDetail {
    background-color: #FFFFFF;

    .project-name {
        text-transform: uppercase;
        margin-bottom: calc(40px + (40 - 16) * ((100vw - 320px) / (1920 - 320)));
    }


    /* span:first-child {
        padding-right: 6px;
        &:after {
            content: ":";
        }
    } */
}