.partnershipsDetail {

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;

        h1 {
            text-align: center;
            width: 100%;
            margin: 60px 0;

            @media screen and (max-width: 1024px) {
                margin: 40px 0;
            }

            @media screen and (max-height: 991px) {
                margin: 40px 0;
            }

            &:not(:first-child) {
                margin-top: 120px;
            }
        }



        .list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            width: 100%;

            .partner {
                position: relative;
                width: calc(25% - 40px);
                margin: 40px 20px;  
                min-width: fit-content;

                .image-container {
                    height: 60px;
                    display: flex;
                    align-items: center;
                }
                
                img {
                    max-height: 60px;
                    
                }

                span {
                    display: block;

                    &.name {
                        padding-top: 20px;
                    }
                }
            }
        }


        .buttons-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 120px 0 60px 0;
        }
    }

}