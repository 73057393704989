.trustedBy {

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .logos-container {
        position: relative;
        overflow: hidden;


        .firstRow, .secondRow {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;

            .logo {
              margin-bottom: 40px;

              @media screen and (max-width: 800px) {
                transform: none!important;
              }
            }

            img {
                position: relative;
                max-height: 60px;
                margin: 0 30px;
            }
        }
    }

}

     
